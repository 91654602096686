<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Nuevo Programa</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Nombre del Programa"
                required
                outlined
                hide-details
                v-model="program.program"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                label="Idioma"
                :items="languages"
                item-text="language"
                item-value="id_language"
                required
                outlined
                hide-details
                v-model="program.id_language"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Horas del Programa"
                required
                outlined
                hide-details
                v-model="program.hours"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Nivel Alcanzado"
                required
                outlined
                hide-details
                v-model="program.level_reached"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Edad Inicio"
                required
                outlined
                hide-details
                v-model="program.age_start"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Edad Límite"
                required
                outlined
                hide-details
                v-model="program.age_end"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()" :loading="isLoading">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddProgram",
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },
  props: { languages: Array, program: Object, clean: { type: Function } },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "program", this.toFormData(this.program))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
